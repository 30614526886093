import React, { useState } from 'react';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  TextField,
  Button,
  InputAdornment,
  Alert,
  Typography,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { styled } from '@mui/material/styles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import AWS from 'aws-sdk';
import { API, Auth } from 'aws-amplify';
import PasswordRequirements from '../authentication/PasswordRequirements';
import useTranslation from '../customHooks/translations';
// import { customFieldOptions } from '../../constants/custom-field-options';
import awsmobile from '../../aws-exports';
import { USER_TYPE } from '../../utils/get-simple-user-from-attributes';
import { createUser, addGroupMember } from '../../graphql/mutations';

AWS.config.update({
  region: awsmobile.aws_project_region,
  accessKeyId: process.env.REACT_APP_AWS_COGNITO_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_COGNITO_SECRET_KEY,
});

const cognito = new AWS.CognitoIdentityServiceProvider();

const StyledDialogTitle = styled(DialogTitle)`
  color: #fff;
  font-size: 2em;
  padding: .5em 1.5em 1em;
  font-weight: 600;
`;

const CreateStudentAccountModal = ({ groupInfo, open, onClose, refetchMembers }) => {
  const translation = useTranslation();
  const [studentEmail, setStudentEmail] = useState('');
  const [studentPassword, setStudentPassword] = useState('');
  const [showPassword, setShowPassword] = useState(true);
  const [isCreating, setIsCreating] = useState(false);
  const [passwordRequirements, setPasswordRequirements] = useState({
    uppercase: { error: false, description: 'oneUppercase' },
    lowercase: { error: false, description: 'oneLowercase' },
    digit: { error: false, description: 'oneDigit' },
    special: { error: false, description: 'oneSpecialCharacter' },
    minLength: { error: false, description: 'more8Characters' },
    maxLength: { error: false, description: 'less16Characters' },
  });
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [generalError, setGeneralError] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [partialSuccess, setPartialSuccess] = useState('');
  const [requirePasswordChange, setRequirePasswordChange] = useState(false);

  const handlePasswordChange = (e) => {
    const password = e.target.value;
    setStudentPassword(password);

    const newRequirements = {
      uppercase: { ...passwordRequirements.uppercase, error: /[A-Z]/.test(password) },
      lowercase: { ...passwordRequirements.lowercase, error: /[a-z]/.test(password) },
      digit: { ...passwordRequirements.digit, error: /[0-9]/.test(password) },
      special: { ...passwordRequirements.special, error: /[^A-Za-z0-9]/.test(password) },
      minLength: { ...passwordRequirements.minLength, error: password.length > 8 },
      maxLength: { ...passwordRequirements.maxLength, error: password.length <= 16 },
    };

    setPasswordRequirements(newRequirements);
  };

  const handleCreateAccount = async () => {
    const creator = await Auth.currentUserInfo();
    const creatorEmail = creator.attributes.email;

    setIsCreating(true);
    // Clear all previous errors
    setEmailError('');
    setPasswordError('');
    setGeneralError('');
    setPartialSuccess('');

    try {
      const username = studentEmail;
      const userEmail = studentEmail + "@commit2act.org";
      const params = {
        UserPoolId: awsmobile.aws_user_pools_id,
        Username: userEmail,
        UserAttributes: [
          {
            Name: 'email',
            Value: userEmail,
          },
          {
            Name: 'name',
            Value: username,
          },
          {
            Name: 'preferred_username',
            Value: username,
          },
          {
            Name: 'email_verified',
            Value: 'true',
          },
          {
            Name: 'custom:type',
            Value: USER_TYPE['MEMBER'],
          },
          {
            Name: 'custom:creator',
            Value: creatorEmail,
          },
          {
            Name: 'custom:groupId',
            Value: String(groupInfo.group_id),
          },
        ],
        TemporaryPassword: studentPassword,
        MessageAction: 'SUPPRESS',
      };

      const user = await cognito.adminCreateUser(params).promise();

      if (user && user.User) {
        try {
          if (!requirePasswordChange) {
            cognito.adminSetUserPassword({
              UserPoolId: awsmobile.aws_user_pools_id,
              Username: userEmail,
              Password: studentPassword,
              Permanent: true
            }).promise();
          }

          const dbUser = await API.graphql({
            query: createUser,
            variables: {
              name: username,
              username: username,
              email: userEmail,
            },
          });

          if (dbUser?.data?.createUser?.user_id) {
            await cognito.adminUpdateUserAttributes({
              UserPoolId: awsmobile.aws_user_pools_id,
              Username: userEmail,
              UserAttributes: [
                {
                  Name: 'custom:firstLogin',
                  Value: 'false'
                }
              ]
            }).promise();

            await API.graphql({
              query: addGroupMember,
              variables: {
                group_id: groupInfo.group_id,
                user_id: dbUser.data.createUser.user_id,
              },
            });

            setIsSuccess(true);
            refetchMembers();
          } else {
            throw new Error('Failed to create user in database');
          }
        } catch (error) {
          console.error('Error in database operations:', error);
          setPartialSuccess('Student Account created but there was an error setting up the profile. Please share the group link and ask them to join group');
          setIsSuccess(true); // Still show success screen since account was created
        }
      } else {
        throw new Error('Failed to create Cognito user');
      }
    } catch (error) {
      console.error('Error creating user:', error);

      switch (error.code) {
        case 'UsernameExistsException':
          setEmailError(translation.createStudentAccount.usernameExists);
          break;
        case 'InvalidPasswordException':
          setPasswordError(translation.createStudentAccount.invalidPassword);
          break;
        case 'InvalidParameterException':
          if (error.message.includes('email')) {
            setEmailError(translation.createStudentAccount.invalidEmail);
          } else {
            setGeneralError(translation.createStudentAccount.unexpectedError);
          }
          break;
        case 'TooManyRequestsException':
          setGeneralError(translation.createStudentAccount.tooManyAttempts);
          break;
        case 'NotAuthorizedException':
          setGeneralError(translation.createStudentAccount.noPermission);
          break;
        case 'ResourceNotFoundException':
          setGeneralError(translation.createStudentAccount.userPoolError);
          break;
        default:
          setGeneralError(translation.createStudentAccount.unexpectedError);
      }
    } finally {
      setIsCreating(false);
    }
  };

  const handleClose = () => {
    setStudentEmail('');
    setStudentPassword('');
    setShowPassword(true);
    setPasswordRequirements({
      uppercase: { error: false, description: 'oneUppercase' },
      lowercase: { error: false, description: 'oneLowercase' },
      digit: { error: false, description: 'oneDigit' },
      special: { error: false, description: 'oneSpecialCharacter' },
      minLength: { error: false, description: 'more8Characters' },
      maxLength: { error: false, description: 'less16Characters' },
    });
    setEmailError('');
    setPasswordError('');
    setGeneralError('');
    setIsSuccess(false);
    onClose();
  }

  const isFormValid = () => {
    const areFieldsFilled = studentEmail && studentPassword;
    const arePasswordRequirementsMet = Object.values(passwordRequirements)
      .every(requirement => requirement.error === true);
    return areFieldsFilled && arePasswordRequirementsMet;
  };

  return (
    <Dialog
      open={open}
    >
      <StyledDialogTitle sx={{ textAlign: { xs: 'center', sm: 'left' } }}>
        {isSuccess ? translation.createStudentAccount.successTitle : translation.createStudentAccount.title}
      </StyledDialogTitle>
      <DialogContent sx={{ p: '3em' }}>
        {isSuccess ? (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2em', pt: '1em' }}>
            <Typography variant="h6">
              {translation.createStudentAccount.shareCredentials}
            </Typography>
            <Box sx={{ p: 3 }}>
              <Typography><strong>{translation.email}:</strong> {studentEmail}@commit2act.org</Typography>
              <Typography><strong>{translation.password}:</strong> {studentPassword}</Typography>
            </Box>
            {partialSuccess && (
              <Alert severity="warning">
                {partialSuccess}
              </Alert>
            )}
            <Button
              variant="contained"
              onClick={handleClose}
              fullWidth
              sx={{ mt: 1 }}
            >
              {translation.done}
            </Button>
          </Box>
        ) : (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2em', pt: '1em' }}>
            <TextField
              required
              label={translation.email}
              value={studentEmail}
              onChange={(e) => {
                // Remove @commit2act.org if user pastes full email
                const input = e.target.value.replace('@commit2act.org', '');
                // Only allow alphanumeric characters, dots, and underscores
                const sanitizedInput = input.replace(/[^a-zA-Z0-9._]/g, '');
                setStudentEmail(sanitizedInput);
              }}
              fullWidth
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: <InputAdornment position="end">@commit2act.org</InputAdornment>,
              }}
              placeholder="username"
              error={!!emailError}
              helperText={emailError || translation.createStudentAccount.emailHelperText}
            />
            <TextField
              required
              label={translation.password}
              type={showPassword ? 'text' : 'password'}
              value={studentPassword}
              onChange={handlePasswordChange}
              fullWidth
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={!!passwordError}
              helperText={passwordError || translation.createStudentAccount.passwordHelperText}
            />
            <PasswordRequirements requirements={passwordRequirements} />

            <FormControlLabel
              control={
                <Checkbox
                  checked={requirePasswordChange}
                  onChange={(e) => setRequirePasswordChange(e.target.checked)}
                />
              }
              label={translation.createStudentAccount.requirePasswordChange}
            />

            {generalError && (
              <Alert severity="error">
                {generalError}
              </Alert>
            )}

            <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
              <Button
                variant="outlined"
                onClick={onClose}
                disabled={isCreating}
                fullWidth
              >
                {translation.cancel}
              </Button>
              <Button
                variant="contained"
                onClick={handleCreateAccount}
                disabled={!isFormValid() || isCreating}
                fullWidth
                sx={{ whiteSpace: 'nowrap' }}
              >
                {isCreating ? translation.creating : translation.createAccount}
              </Button>
            </Box>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default CreateStudentAccountModal;
