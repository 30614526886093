const translations = {
  changeLanguage: 'Language: ',
  welcomeName: 'Welcome {0}!',
  signIn: 'Sign In',
  signInGoogle: 'Sign In with Google',
  signInFacebook: 'Sign In with Facebook',
  email: 'Email',
  emailRequired: 'Email required',
  enterYourEmail: 'Enter your email',
  invalidEmail: 'Invalid Email',
  password: 'Password',
  passwordRequired: 'Password Required',
  enterYourPassword: 'Enter your password',
  forgotPassword: 'Forgot your password?',
  createAccount: 'Create an Account',
  creating: "Creating...",
  creatingYourAccount: 'Creating your account',
  verifyAccount: 'Verify Account',
  passwordReset: 'Password Reset',
  setNewPassword: 'Set New Password',
  createNewPassword: 'Create New Password',
  age: 'age',
  welcome: 'Welcome',
  back: 'Back',
  or: 'Or',
  incorrectLogin: 'Incorrect username or password.',
  name: 'Name',
  username: 'Username',
  usernameRequired: 'Username Required',
  enterYourUsername: 'Enter your username',
  confirmPassword: 'Confirm Password',
  passwordSettings: 'Your password must have the following:',
  oneUppercase: 'At least one uppercase letter',
  oneLowercase: 'At least one lowercase letter',
  oneDigit: 'At least one digit',
  oneSpecialCharacter: 'At least one special character',
  more8Characters: 'Should be more than 8 characters',
  less16Characters: 'Should be less than 16 characters',
  fillAllFields: 'Please fill in all fields.',
  validEmail: 'Please enter a valid email.',
  validEmailAccount: 'Please enter a valid email or create an account',
  confirmEmail: 'Confirm Your Email',
  here: 'here',
  passwordNotMatch: 'Passwords do not match',
  signUp: 'Sign Up',
  enterEmailAddress:
    "Enter your email address and we'll send you a code to help you reset your password.",
  sendResetCode: 'Send reset code',
  enterResetCode: 'Enter reset code',
  checkEmail: 'Please check your email',
  forResetCodeAndCreatePassword: 'for a reset code and create a new password.',
  enterCorrectResetCode: 'Please enter correct reset code.',
  reEnterPassword: 'Re-enter the password',
  updatePassword: 'Update Password',
  updating: "Updating...",
  emailAlreadyExists: 'An account with the given email already exists.',
  checkEmailForConfirmationCode:
    'Please check your email for a confirmation code. This may take several minutes.',
  invalidVerificationCode:
    'Invalid verification code provided, please try again.',
  newVerificationCodeSentSuccesfully:
    'New verification code sent successfully.',
  enterConfirmationCode: 'Enter your confirmation code.',
  didNotReceiveCode: "Didn't receive your verification code?",
  resendCode: 'Resend Code',
  replaceTemporaryPassword:
    'Please replace your temporary password with a new password for',
  setPassword: 'Set Password',
  alertNewActionTitle: 'New Actions In Need of Validation',
  alertNewActionText: 'You have {0} actions to validate!',
  startValidating: 'Start Validating',
  alertActionsPendingTitle: '{0} Actions Pending Validation',
  alertActionsPendingText:
    'The impact of your pending actions is {0} of CO2 saved',
  recentProgress: 'Recent Progress',
  logANewAction: 'Log A New Action',
  co2SavedWeek: 'CO2 Saved This Week',
  collectiveImpact: 'Collective Impact',
  myGroups: 'My Groups',
  myActions: 'My Actions',
  createNewGroup: 'Create New Group',
  globalGroups: 'Global Groups',
  globalUsers: 'Global Users',
  totalCO2Saved: 'Total CO2 Saved',
  weeklyCO2Saved: 'Weekly CO2 Saved',
  leaderboardDisplaying: 'Displaying Global {tab} ranked by ',
  rank: 'Rank',
  totalCO2: 'Total CO2',
  totalPoints: 'Total Points',
  membersCount: 'Members Count',
  weeklyCO2: 'Weekly CO2',
  weeklyPoints: 'Weekly Points',
  totalCO2Colon: 'Total CO2:',
  totalPointsColon: 'Total Points:',
  weeklyCO2Colon: 'Weekly CO2:',
  weeklyPointsColon: 'Weekly Points:',
  leaderboards: 'Leaderboards',
  noMyGroups: 'No groups to display. Create or join a group to get started!',
  viewGroupStats: 'View Group Stats',
  myContributions: 'My Contributions',
  myContribution: 'My Contribution',
  allOtherMembers: 'All Other Members',
  joinGroup: 'Join Group',
  read: 'Read',
  more: 'More',
  less: 'Less',
  membersColon: 'Members:',
  public: 'Public',
  private: 'Private',
  leaveGroup: 'Leave Group',
  currentPlace: 'Current Place',
  labelRowsPerPage: 'Rows per page:',
  of: 'of',
  groupInfo: 'Group Info',
  memberActions: 'Member Actions',
  groupMembers: 'Group Members',
  addMembers: 'Add Members',
  editGroupInfo: 'Edit Group Info',
  about: 'About',
  groupOwners: 'Group Owners',
  view: 'View',
  noActions: 'There are currently no actions to display.',
  co2SavedColon: 'CO2 Saved:',
  totalPointsEarnedColon: 'Total Points Earned:',
  unapprove: 'Unapprove',
  promoteUserToOwner: 'Promote User to Group Owner',
  demoteUserToMember: 'Demote User to Group Member',
  removeUserFromGroup: 'Remove User From Group',
  roleColon: 'Role:',
  yourRoleColon: 'Your Role:',
  viewUserProfile: 'View User Profile',
  success: 'Success!',
  warning: 'Warning!',
  successUserNowOwner: '{0} is now a group owner',
  successUserNowMember: '{0} is now a group member',
  successUserNowRemoved: '{0} has been removed from the group',
  demotionErrorTitle: 'Demotion Error',
  demotionErrorText: 'Groups must have at least 1 group owner',
  existingUsersTitle: "For Existing Users",
  educatorsTitle: "For Educators",
  createStudentAccountButton: "Create Student Account",
  addMembersDescription:
    'Add users to this group by sending them your group link',
  addMembersDescriptionPassword:
    'Add users to this group by sending them your group link and password',
  groupLinkLabel: 'Your Group Link is:',
  groupPasswordLabel: 'Your Group Password is:',
  linkCopied: 'Link Copied!',
  passwordCopied: 'Password Copied!',
  createStudentAccount: {
    title: 'Create Student Account',
    successTitle: 'Account Created Successfully!',
    shareCredentials: 'Please share these credentials with the student:',
    emailHelperText: 'This will be used as both email and username',
    passwordHelperText: 'Follow all the below requirements.',
    partialSuccessWarning: 'Student Account created but there was an error setting up the profile. Please share the group link and ask them to join group',
    usernameExists: 'This username is already taken. Please choose a different one.',
    invalidPassword: 'The password does not meet the requirements. Please check the password requirements below.',
    invalidEmail: 'Please enter a valid email address.',
    tooManyAttempts: 'Too many attempts. Please wait a moment before trying again.',
    noPermission: 'You do not have permission to create user accounts.',
    userPoolError: 'User pool not found. Please contact support.',
    unexpectedError: 'An unexpected error occurred. Please try again later.',
    requirePasswordChange: 'Require student to set their own password when they login?',
  },
  editGroupEmptyError: 'Empty group name',
  editGroupTakenError: 'Group name is taken',
  editGroupPasswordError: 'Empty password',
  groupUploadIcon: 'Upload Group Icon',
  groupNameEmptyError: 'Field must be completed',
  groupNameTakenError: 'A group already exists with the given name',
  description: 'Description',
  groupPrivacy: 'Group Privacy',
  groupPasswordEmptyError: 'Private groups must have a password',
  deleteGroup: 'Delete Group',
  saveGroupInfo: 'Save Group Info',
  join: 'Join',
  cancel: 'Cancel',
  delete: 'Delete',
  leave: 'Leave',
  groupUpdatedMsg: 'The group has been updated',
  groupDeletedMsg: '{0} has been deleted!',
  homepageRedirectMsg: 'You will now be directed to the homepage',
  deleteGroupPrompt: 'Delete {0}',
  deletePrompAlert: 'This is irreversible',
  logout: 'Logout',
  leaveGroupLastMemberWarning:
    'This group will be deleted since there will be no group members.',
  promoteOtherUserBeforeLeavingGroup:
    'You must promote another user to group owner before leaving the group.',
  removeGroupOwner: 'Remove Group Owner',
  promoteOtherUserBeforeRemovingUser:
    'You must promote another user to group owner before removing this user.',
  leftGroup: 'You have left the the group!',
  joinGroupAlreadyMember: 'You are already a member of this group!',
  joinGroupTitle: 'You have been invited to join {0}',
  successRedirectProfilePage: "Success! Redirecting to {0}'s profile page",
  joinGroupConfirm: 'Please confirm by selecting Join.',
  joinGroupSelectCancel: 'Select Cancel to be redirected to the group page.',
  joinGroupEnterPassword:
    'To join this group, please enter the group password and select Join.',
  incorrectPassword: 'Incorrect Password',
  logAction: 'Log Action',
  logActionMobile: 'Log Action',
  dashboard: 'Dashboard',
  home: 'Home',
  groups: 'Groups',
  findGroup: 'Find Group',
  createGroup: 'Create Group',
  validateActions: 'Validate Actions',
  adminDashboard: 'Admin Dashboard',
  myAccount: 'My Account',
  logActionTitle: 'Log New Action',
  logActionStep1: 'Select an Action',
  logActionStep2: 'Add Action',
  logActionStep3: 'Nice Work',
  logActionStep4: 'CO2 Saved',
  logActionStep5: 'Bonus Question',
  logActionStep6: 'Share On Social!',
  next: 'Next',
  previous: 'Previous',
  chooseDate: 'Date Complete',
  mustBeNumber: 'Input must be a number or decimal greater than 0',
  imageValidationText:
    'Please upload an image related to your action item for verification',
  imageValidationDimensions: 'Minimum dimensions 100x100 px',
  imageValidationError: 'This Is Not An Image File',
  imageValidationSelected: 'Image Selected!',
  imageValidationDrop: 'Drop Your Image Here, Or',
  browse: 'Browse',
  selectFile: 'Select File',
  showUsTheProof: 'Show us the proof!',
  uploadImageInfo:
    'Keep it professional. An AI may check your photo but so may a human.',
  uploadImage: 'Upload Image',
  topTips: 'Top Tips',
  uploadPhoto: 'Upload a photo!',
  skip: 'Skip',
  actionFactResponse: 'Thank you for submitting an action!',
  actionFactAllFacts: 'You have viewed all the facts for this action',
  bonusQuizChoicesLabel: 'Answer Choices',
  bonusQuizSubmit: 'Submit Quiz',
  bonusQuizSkip: 'Skip Quiz',
  bonusQuiz: 'Bonus Quiz',
  correct: 'Correct!',
  incorrect: 'Incorrect!',
  finish: 'Finish',
  tryAgain: 'Try Again',
  co2SavedScreenState: 'Your action is currently being',
  submitted: 'submitted',
  validated: 'validated',
  co2SavedScreenSkip: 'Press skip to view validation results at a later time',
  thankYou: 'Thank you!',
  co2SavedScreenValidated: 'Your action has been validated',
  co2SavedScreenSaved: 'Nice Work! You have saved {0} g of CO2!',
  co2SavedScreenApproval: 'Your action is awaiting admin approval',
  co2SavedScreenImpact: 'The impact of your action is {0} g of CO2 saved',
  co2SavedScreenAnother: 'Add Another Action',
  takeBonusQuiz: 'You can earn even more points by taking the bonus quiz!',
  createGroupTitle: 'Create New Group',
  uploadGroupIcon: 'Upload Group Icon',
  groupName: 'Group Name',
  groupDescription: 'Group Description',
  redirectGroupPage: 'You will now be redirected to the group page',
  nameC: 'Name:',
  emailC: 'Email:',
  editInfo: 'Edit Info',
  loggedActions: 'Logged Actions',
  awaitingValidation: 'Awaiting Validation',
  notPassValidation: 'Did Not Pass Validation',
  noValidatedActions: 'There are no validated actions to show',
  noUnvalidatedActions: 'There are no unvalidated actions to show',
  noFailedActions: 'There are no failed actions to show',
  editAccountInfoTitle: 'Edit Account Information',
  uploadProfilePhoto: 'Upload Profile Photo',
  requiredFieldsMessage: 'Please fill out all required fields',
  saveNameEmailPhoto: 'Save Name, Email, Photo',
  deleteAccount: 'Delete Account',
  deleteAccountWarning: 'Are you sure you want to delete your account? All of your information will be removed and you will be signed out.',
  deleteAccountProgress: 'Deleting your account...',
  deleteGroupsMsg: 'Before deleting your account, please assign new admins to the following groups you own:',
  changePassword: 'Change Password',
  currentPassword: 'Current Password',
  currentPasswordMessage: 'Current password is incorrect',
  newPassword: 'New Password',
  confirmNewPassword: 'Confirm New Password',
  passwordsDontMatch: 'Passwords do not match.',
  savePassword: 'Save Password',
  toggleCurrentPassword: 'toggle current password visibility',
  toggleNewPassword: 'toggle new password visibility',
  toggleConfirmNewPassword: 'toggle confirm new password visibility',
  findGroupTitle: 'Search For A Group',
  findGroupDescription: 'Enter the group name of any public group',
  findGroupErrorSearch: 'Your search for "{0}" did not match any public groups',
  findGroupTopGroups:
    'Here are the top 5 public groups who you may want to be a part of',
  searchResults: 'Search results',
  validateActionsUsersWithoutGroupsTab: 'Users Without Groups',
  validateActionsAllUnvalidatedActionsTab: 'All Unvalidated Actions',
  searchByC: 'Search By:',
  actionName: 'Action Name',
  search: 'Search',
  groupNameSearchEmptySearch:
    'Your search for "{0}" did not match any of your groups',
  groupNameSearchNoGroups: 'You currently do not own any groups',
  filteringBy: 'Filtering By:',
  currentFilter: 'Current filter',
  changeFilter: 'Change filter',
  sortingBy: 'Sorting by',
  changeSortingOrder: 'Change Sorting Order',
  groupNameSearchAllFilter: 'All My Groups',
  validateActionsNoActions: 'There are no actions in need of validation',
  actionNameSearchAllFilter: 'All Action Types',
  actionNameSearchEmptySearch:
    'Your search for "{0}" did not match any action type names',
  actionNameSearchNoActions: 'There are currently no action types',
  submittedInC: 'Submitted in:',
  accept: 'Accept',
  reject: 'Reject',
  actions: 'Actions',
  clearSearch: 'Clear Search',
  totalUsers: 'Total Users',
  totalGroups: 'Total Groups',
  actionsSubmitted: 'Actions Submitted',
  actionsSubmittedFilter: 'Actions submitted during the past {0}',
  allTimeStats: 'All Time Stats',
  totalActionsSubmitted: 'Total Number of Actions Submitted',
  actionsFirstAnswerCorrect: 'Actions With First Quiz Answer Correct',
  actionsFirstAnswerIncorrect: 'Actions With First Quiz Answer Incorrect',
  actionsSubmittedWithImage: 'Actions Submitted With Image',
  actionsSubmittedWithoutImage: 'Actions Submitted Without Image',
  numberActionsSubmittedWithImage: 'Number of Actions Submitted With Image',
  numberActionsSubmittedWithoutImage:
    'Number of Actions Submitted Without Image',
  date: 'Date',
  gramsCO2Saved: 'Grams CO2 Saved',
  applyFilter: 'Apply Filter',
  createAction: 'Create Action',
  manageActions: 'Manage Actions',
  manageQuizQuestions: 'Manage Quiz Questions',
  sevenDays: '7 Days',
  thirtyDays: '30 Days',
  ninetyDays: '90 Days',
  oneYear: 'Year',
  co2SavedDuring: 'CO2 Saved during the past {0}',
  userNoteGroupOwner: ' (Owner)',
  totalGramsSavedCO2PerDayAllActions:
    'Total Grams of CO2 Saved Per Day For All Actions',
  continue: 'Continue',
  done: 'Done',
  submit: 'Submit',
  extraPoints: 'Get Extra Points!',
  logActionButtonAddAnother: 'Add Another',
  logActionButtonAllDone: 'All Done',
  orSpace: 'or ',
  commit2ActHashtag: '#Commit2Act',
  logActionStepCompleted: 'Completed',
  logActionStepCurrent: 'Current',
  logActionStepOfTotal: 'Step {0} of {1}',
  logActionItemsErrorAlt: 'Error',
  logActionShareSummarySimple:
    'I dare you to match my action by saving +{0}g of CO2!',
  share: 'Copy & Share',
  shareOnInstagram: 'Share on Instagram',
  shareOnTikTok: 'Share on TikTok',
  copyButtonCopy: 'Copy',
  copyLink: 'copy link',
  toShareManually: ' to share manually',
  copyButtonCopied: 'Copied!',
  logActionValidationLoading: 'Validating action details',
  logActionValidationComplete: 'Validation complete',
  your: 'Your',
  actionIsSubmitted: 'action is being submitted',
  savingThePlanet: 'Amazing, You are saving the planet!',
  checkAnswer: 'Check Answer',
  signup: {
    progressLabel: '{0} percent',
    stepLabel: 'Step {0}',
    buttonBack: 'Back',
    buttonNext: 'Next',
    buttonContinue: 'Continue',
    buttonComplete: 'Complete',
    buttonConfirm: 'Confirm',
    buttonSignup: 'Sign Up',
    buttonLetsGo: "Let's Go!",
    buttonLetsTakeAction: "Let's Take Action!",
    infoPrimaryHeadingOne: 'Great!',
    infoPrimarySubHeading: "Let's get started",
    onboardStartHeading: 'Before we start taking action...',
    onboardStartSubHeading:
      'We want to understand your experience level a little better',
    infoSecondaryHeading: 'Great!',
    infoSecondarySubHeading: "Let's get started",
    avatar: 'Avatar',
    avatarSelectAriaLabel: 'Avatar Select Label',
    avatarSelectHeading: 'Pick your avatar',
    avatarSelectSubHeading:
      'Choose which sea creature you want to use in the app!',
    avatarReviewHeading: 'Great choice!',
    avatarReviewSubHeading:
      'The {0} will be your companion throughout your journey',
    subjectExperiencedHeading: 'How experienced are you with climate action?',
    subjectFactHeading: 'Fantastic! Did you know..',
    usageIntendHeading: 'How would you prefer to use this app?',
    usageInstructHeading: "Great! Here's a look inside:",
    usageInstructSubheading:
      'Commit2Act allows you to track the impact of your daily actions. You can compete with your classmates, school groups or other schools to do good. You can test your knowledge with quizzes and harness AI image recognition to validate your impact images!',
    actionsSelectHeading: "Let's act now",
    actionsSelectSubheading:
      'Which actions would be easiest for you to take part of your daily routine:',
    actionsImpactHeading: 'Amazing!',
    actionsImpactSubheading1a: 'Your actions could save up to ',
    actionsImpactSubheading1b: ' CO2 each week!',
    actionsImpactSubheading2:
      'If you take action with your friends and community this impact would be much, much higher!',
    actionsImpactSubheading3:
      'Plus you can earn even more bonus points by adding an action each day!',
    actionsIgnoreHeading: "Are there any actions you can't do?",
    actionsIgnoreSubheading:
      'For example, if you live in a community without public transit, or do not have access to clean water, these actions may not be possible. We will not prompt you to take any actions you cannot do.',
    onboardEndHeading: "You're all set!!!",
    onboardEndSubheading: "We can't wait to see the impact you will achieve!",
    skipOnboardText: 'In a hurry to start acting?',
    skipOnboardLink: 'Skip for now',
    nickname: 'Nickname',
    enterYourNickname: 'Enter your nickname',
    ageRange: 'Age Range',
    country: 'Country',
    province: 'Province',
    loadingText: 'Loading',
    didNotReceiveConfirmation: "Didn't receive a confirmation code? ",
    contactCommit2Act: 'Contact Commit2Act',
    loadingConfirmation: 'This can take up to 30 seconds...',
  },
  textInput: {
    togglePasswordVisibility: 'Toggle Password Visibility',
    requiredTextInput: 'This is required.',
    invalidEmail: 'Invalid email.',
  },
  logActionPage: {
    noActionsAvailable: 'No actions available',
    loadingActions: 'Loading actions',
    noActionItemsAvailable: 'No action items available',
    loadingActionItems: 'Loading action items',
    imageUploadInstructions: 'Image upload instructions',
    actionValidation: 'Your action has been submitted for validation.',
    question: 'Did you know?',
    cO2Saved: 'of CO2 saved!',
    pointsEarned: 'Points Earned!',
    takeTheBonusQuiz: 'Take the bonus quiz for extra points!',
    savingThePlanet: 'Amazing, you are saving the planet!',
    pointsEarnedForYourAction: 'You earned {0} points for your action.',
    greatJob: 'Great job! You earned {0} points.',
    tryAgain: 'You can try again for {0} points. Would you like to try again?',
    dareYouToMatch: 'I dare you to match my {0} points by taking action now!'
  },
  dashboardPage: {
    statProgress: 'Your stat-tacular progress',
    yourActions: 'Your actions',
    growYourKnowledge: 'Grow your knowledge and understanding',
    leaderboard: 'The Leaderboard',
    yourImpact: 'Your impact',
    actionsThisWeek: 'Actions this week',
    weeklyActions: '{0} Actions',
    awaitingValidation: 'Are Awaiting Validation',
    actionsBreakdown: 'My Actions Breakdown',
    actionsTaken: 'Actions Taken',
    weeklyCO2: 'Weekly CO2',
    totalCO2Saved: 'Total CO2 Saved',
    currentStreak: 'Current Streak',
    actionsTakenColon: 'Actions Taken:',
    pointsEarnedColon: 'Points Earned:',
    totalPointsColon: 'Total Points:',
    logNow: 'Log Now',
    emptyAction1: 'You have done ',
    emptyAction2: ' actions. Need some inspo for ',
    emptyAction3: 'how to do this action',
    co2Saved: '{0} saved',
    thisWeek: 'this week',
    fromLastWeek: 'from last week',
  },
  userProfile: {
    title: "{0}'s Profile",
    resetPasswordButton: "Reset Account Password",
    groupsTitle: "{0}'s Groups",
    actionsTitle: "{0}'s Actions",
    noGroupsMessage: "{0} is not a member in any groups",
    dialogTitle: "Reset Password",
    successMessage: "Password successfully updated",
  },
};

export default translations;
