import React, { useEffect, useState } from 'react';
import { Box, Button, Stack, Typography, Grid, Avatar, Dialog, DialogTitle, DialogContent, IconButton, TextField, InputAdornment, Alert, CircularProgress } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { API, Auth } from 'aws-amplify';
import {
  getAllSubmittedActionsForUser,
  getSingleUser,
  getAllGroupsForUser,
} from '../graphql/queries';
import SubmittedActionCard from '../components/SubmittedActionCard';
import AWS from 'aws-sdk';
import awsmobile from '../aws-exports';
import { styled } from '@mui/material/styles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import PasswordRequirements from '../components/authentication/PasswordRequirements';
import useTranslation from '../components/customHooks/translations';

AWS.config.update({
  region: awsmobile.aws_project_region,
  accessKeyId: process.env.REACT_APP_AWS_COGNITO_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_COGNITO_SECRET_KEY,
});

const cognito = new AWS.CognitoIdentityServiceProvider();

const StyledDialogTitle = styled(DialogTitle)`
  color: #fff;
  font-size: 2em;
  padding: .5em 1.5em 1em;
  font-weight: 600;
`;

const UserProfile = () => {
  const translation = useTranslation();
  const { userId } = useParams();
  const [user, setUser] = useState();
  const [userType, setUserType] = useState();
  const [validatedActions, setValidatedActions] = useState();
  const [groups, setGroups] = useState();
  const [showMore, setShowMore] = useState();
  const [showResetButton, setShowResetButton] = useState(false);
  const [isResetPasswordOpen, setIsResetPasswordOpen] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [passwordRequirements, setPasswordRequirements] = useState({
    uppercase: { error: false, description: 'oneUppercase' },
    lowercase: { error: false, description: 'oneLowercase' },
    digit: { error: false, description: 'oneDigit' },
    special: { error: false, description: 'oneSpecialCharacter' },
    minLength: { error: false, description: 'more8Characters' },
    maxLength: { error: false, description: 'less16Characters' },
  });
  const navigate = useNavigate();

  const getCurrentUserInfo = async () => {
    //if currently logged in user has the same id as the user profile link id, redirect user to their account settings page
    const cognitoUserEntry = await Auth.currentAuthenticatedUser();
    setUserType(cognitoUserEntry.attributes['custom:type']);
    const id = cognitoUserEntry.attributes['custom:id'];
    const currentUserEmail = cognitoUserEntry.attributes.email;
    if (id === userId) {
      navigate(`/account-settings`);
    }

    const [userRes, userActionRes, userGroupRes] = await Promise.all([
      API.graphql({
        query: getSingleUser,
        variables: { user_id: userId },
      }),
      API.graphql({
        query: getAllSubmittedActionsForUser,
        variables: { user_id: userId },
      }),
      API.graphql({
        query: getAllGroupsForUser,
        variables: { user_id: userId },
      }),
    ]);

    // Add check for creator
    const params = {
      UserPoolId: awsmobile.aws_user_pools_id,
      Username: userRes.data.getSingleUser.email
    };

    try {
      const cognitoUser = await cognito.adminGetUser(params).promise();
      const creatorAttribute = cognitoUser.UserAttributes.find(
        attr => attr.Name === 'custom:creator'
      );

      if (creatorAttribute && creatorAttribute.Value === currentUserEmail) {
        setShowResetButton(true);
      }
    } catch (error) {
      console.error('Error checking user creator:', error);
    }

    setUser(userRes.data.getSingleUser);
    //filter for all validated actions
    const allActions = userActionRes.data.getAllSubmittedActionsForUser;
    const validated = allActions.filter((action) => action.is_validated);
    setValidatedActions(validated);

    //filter for all public groups
    const allGroups = userGroupRes.data.getAllGroupsForUser;
    const publicGroups = allGroups.filter((group) => group.is_public);
    setGroups(publicGroups);
  };

  const getActions = async () => {
    const res = await API.graphql({
      query: getAllSubmittedActionsForUser,
      variables: { user_id: userId },
    });
    //filter for all validated actions
    const allActions = res.data.getAllSubmittedActionsForUser;
    const validated = allActions.filter((action) => action.is_validated);
    setValidatedActions(validated);
  };

  useEffect(() => {
    getCurrentUserInfo();
  }, []);

  const handlePasswordChange = (e, isConfirm = false) => {
    const password = e.target.value;
    if (isConfirm) {
      setConfirmPassword(password);
    } else {
      setNewPassword(password);
      const newRequirements = {
        uppercase: { ...passwordRequirements.uppercase, error: /[A-Z]/.test(password) },
        lowercase: { ...passwordRequirements.lowercase, error: /[a-z]/.test(password) },
        digit: { ...passwordRequirements.digit, error: /[0-9]/.test(password) },
        special: { ...passwordRequirements.special, error: /[^A-Za-z0-9]/.test(password) },
        minLength: { ...passwordRequirements.minLength, error: password.length > 8 },
        maxLength: { ...passwordRequirements.maxLength, error: password.length <= 16 },
      };
      setPasswordRequirements(newRequirements);
    }
  };

  const handleResetPassword = async () => {
    if (newPassword !== confirmPassword) {
      setError(translation.passwordNotMatch);
      return;
    }

    setIsUpdating(true);
    setError('');

    try {
      const cognito = new AWS.CognitoIdentityServiceProvider();
      await cognito.adminSetUserPassword({
        UserPoolId: awsmobile.aws_user_pools_id,
        Username: user.email,
        Password: newPassword,
        Permanent: true
      }).promise();

      setSuccess(true);
    } catch (error) {
      console.error('Error updating password:', error);
      setError(error.message || 'Failed to update password');
    } finally {
      setIsUpdating(false);
    }
  };

  const isFormValid = () => {
    const areFieldsFilled = newPassword && confirmPassword;
    const arePasswordRequirementsMet = Object.values(passwordRequirements)
      .every(requirement => requirement.error === true);
    return areFieldsFilled && arePasswordRequirementsMet;
  };

  const handleCloseDialog = () => {
    setIsResetPasswordOpen(false);
    setNewPassword('');
    setConfirmPassword('');
    setShowPassword(false);
    setError('');
    setSuccess(false);
  };

  const renderValidatedActionCards = () => {
    //return if validatedActions is not null or undefined and contains at least 1 item
    if (Array.isArray(validatedActions) && validatedActions.length !== 0) {
      return (
        <Box
          sx={{
            height: '110vh',
            overflow: 'auto',
            padding: '0.25em',
          }}
        >
          <Stack spacing={2}>
            {showMore
              ? validatedActions.map((action, index) => (
                  <SubmittedActionCard
                    key={index}
                    action={action}
                    showUnapproveButton={userType === 'Admin'}
                    getActions={getActions}
                  />
                ))
              : validatedActions
                  .slice(0, 3)
                  .map((action, index) => (
                    <SubmittedActionCard
                      key={index}
                      action={action}
                      showUnapproveButton={userType === 'Admin'}
                      getActions={getActions}
                    />
                  ))}
            <Button
              sx={{ mt: '3em' }}
              variant="outlined"
              onClick={() => setShowMore(!showMore)}
            >
              {translation.view} {showMore ? translation.less : translation.more}
            </Button>
          </Stack>
        </Box>
      );
    } else {
      return (
        <Typography variant="subtitle2">
          {translation.noValidatedActions}
        </Typography>
      );
    }
  };

  const renderUserGroups = () => {
    return (
      <>
        {/* show error message if user is not in any groups */}
        {groups && groups.length === 0 && (
          <Typography component="div" variant="subtitle2">
            {translation.formatString(translation.userProfile.noGroupsMessage, user.name )}
          </Typography>
        )}
        {/* else display all of the user's public groups */}
        {groups && groups.length !== 0 && (
          <Grid
            item
            container
            columnSpacing={{ xs: 0, md: 1 }}
            sx={{
              width: '100%',
              padding: '1.5em',
              mt: '2em',
              alignItems: 'center',
              flexWrap: 'wrap',
              overflow: 'auto',
            }}
          >
            {groups.map((group, index) => (
              <Grid
                container
                item
                xs={6}
                sm={4}
                md={2}
                sx={{
                  justifyContent: 'center',
                  wordBreak: 'break-word',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
                key={index}
              >
                <>
                  <Avatar
                    alt={group.group_name}
                    src={group.group_image ? group.group_image : null}
                    sx={{
                      width: 100,
                      height: 100,
                      ':hover': { opacity: '0.8', cursor: 'pointer' },
                    }}
                    onClick={() =>
                      navigate(`/group-profile/${group.group_name}`)
                    }
                  >
                    {group.group_name.charAt(0)}
                  </Avatar>
                  <Typography variant="subtitle2" sx={{ mt: '0.5em' }}>
                    {group.group_name}
                  </Typography>
                </>
              </Grid>
            ))}
          </Grid>
        )}
      </>
    );
  };

  if (!user) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      {user && (
        <Box sx={{ textAlign: { xs: 'center', md: 'left' } }}>
          <Typography
            variant="h1"
            sx={{ mt: { xs: '1.5em', md: '0' }, mb: '1.5em' }}
          >
            {translation.formatString(translation.userProfile.title, user.name)}
          </Typography>
          <Grid
            container
            sx={{
              flexDirection: { xs: 'column', md: 'row' },
              alignItems: { xs: 'center', md: 'flex-start' },
            }}
          >
            <Grid
              item
              xs={12}
              md={3}
              sx={{ display: 'flex', justifyContent: 'center' }}
            >
              <Avatar
                variant="rounded"
                src={user.avatar}
                sx={{
                  width: 100,
                  height: 100,
                }}
              ></Avatar>
            </Grid>
            <Grid
              item
              xs={12}
              md={9}
              sx={{
                width: '100%',
                borderRadius: '5px',
                padding: '1.5em',
                display: 'flex',
                gap: '1em',
                justifyContent: { xs: 'center', md: 'space-between' },
                flexFlow: { xs: 'none', md: 'row wrap' },
                flexDirection: { xs: 'column' },
                alignItems: { xs: 'center', md: 'end' },
                overflow: 'auto',
                mt: { xs: '1em', md: '0' },
              }}
            >
              <Box>
                <Typography variant="h7" component="div">
                  <Typography variant="h3" component="span" sx={{ mr: '1em' }}>
                    {translation.name}:
                  </Typography>
                  {user.name}
                </Typography>
                <Typography variant="h7" component="div">
                  <Typography variant="h3" component="span" sx={{ mr: '1em' }}>
                    {translation.email}:
                  </Typography>
                  {user.email}
                </Typography>
              </Box>
              {showResetButton && (
                <Button
                  variant="outlined"
                  onClick={() => setIsResetPasswordOpen(true)}
                  sx={{paddingInline: '2.5rem'}}
                >
                  {translation.userProfile.resetPasswordButton}
                </Button>
              )}
            </Grid>
          </Grid>
          <Typography variant="h2" sx={{ m: '2.5em 0 1.25em' }}>
            {translation.formatString(translation.userProfile.groupsTitle, user.name)}
          </Typography>
          {renderUserGroups()}
          <Typography variant="h2" sx={{ m: '2.5em 0 1.25em' }}>
            {translation.formatString(translation.userProfile.actionsTitle, user.name)}
          </Typography>
          {renderValidatedActionCards()}
        </Box>
      )}
      <Dialog open={isResetPasswordOpen} onClose={handleCloseDialog}>
        <StyledDialogTitle>
          {translation.userProfile.dialogTitle}
        </StyledDialogTitle>
        <DialogContent sx={{ p: '3em' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2em', pt: '1em' }}>
            {success ? (
              <>
                <Alert severity="success">
                  {translation.userProfile.successMessage}
                </Alert>
                <Button variant="contained" onClick={handleCloseDialog}>
                  {translation.done}
                </Button>
              </>
            ) : (
              <>
                <TextField
                  required
                  label={translation.newPassword}
                  type={showPassword ? 'text' : 'password'}
                  value={newPassword}
                  onChange={(e) => handlePasswordChange(e)}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                          {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  required
                  label={translation.confirmNewPassword}
                  type={showConfirmPassword ? 'text' : 'password'}
                  value={confirmPassword}
                  onChange={(e) => handlePasswordChange(e, true)}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)} edge="end">
                          {showConfirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <PasswordRequirements requirements={passwordRequirements} />

                {error && <Alert severity="error">{error}</Alert>}

                <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                  <Button
                    variant="outlined"
                    onClick={handleCloseDialog}
                    disabled={isUpdating}
                    fullWidth
                  >
                    {translation.cancel}
                  </Button>
                  <Button
                    sx={{ whiteSpace: "nowrap", flexShrink: 0, width: "auto"}}
                    variant="contained"
                    onClick={handleResetPassword}
                    disabled={!isFormValid() || isUpdating}
                    fullWidth
                  >
                    {isUpdating ? translation.updating : translation.updatePassword}
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UserProfile;
